import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import '@/permission';
import '@/styles/style.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 确保导入 Element Plus 的样式

const pinia = createPinia();
const app = createApp(App);
console.log(app, 'app');
app.use(ElementPlus);
app.use(pinia);
app.use(router);
app.mount('#app');
